<template>
  <div class="page-right-content mt-16 vacancy-right-content" style="display: block">
    <div class="vacancy-candidates">
      <router-link class="vacancy-candidates_list" :to="`/vacancies/vacancy/${vacancy.id}/folder/all`">
        <v-skeleton-loader v-if="vacancyLoading" type="avatar" v-for="i in 3" :key="i"/>
        <CandidateMedia
          v-for="candidate in firstCandidates"
          :key="candidate.candidate.id"
          :candidate="candidate"
        />
        <span class="candidates-count" v-if="vacancy.candidates && vacancy.candidates.length > 6">+{{ vacancy.candidates.length - 6 }}</span>
      </router-link>
    </div>
        <div class="owner mb-5" v-if="vacancy.access_type !== 'owner' && vacancy.user">
          <span>Владелец вакансии: </span>
          <v-list-item>
            <div class="candidates-list-item_media">
              <CandidateMedia :candidate="vacancy.user"/>
            </div>
            <div class="candidates-list-item_inner">
              <div class="candidates-list-item_title">{{ vacancy.user.name }} {{ vacancy.user.surname ? vacancy.user.surname : '' }}
              </div>
              <div v-if="vacancy.user.email" class="candidates-list-item_subtitle">{{ vacancy.user.email }}</div>
            </div>
          </v-list-item>
        </div>
    <v-expansion-panels
      v-if="vacancy.user && vacancy.user.id === $store.state.user.id && vacancy.access && vacancy.access.length" flat class="mb-4 shared-users"
                        accordion
                        style="max-width: 300px"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="px-0">Пользователи с доступом к вакансии</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list width="100%" class="py-0">
            <v-list-item v-for="user in vacancy.access" :key="user.id" class="px-0">
              <div class="selected-candidates_item">
                <div class="candidate-media mr-4">
                  <img v-if="user.user.image" :src="user.user.image" alt="user image">
                  <div v-else class="candidate-media_content no-image">
                    {{ user.user.name ? user.user.name[0].toUpperCase() : '' }}
                    <span>{{ user.user.surname ? user.user.surname[0].toUpperCase() : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="candidates-list-item_inner" style="max-width: 160px">
                <div class="candidates-list-item_title">{{ user.user.email ? user.user.email : user.user.phone }}</div>
                <div class="candidates-list-item_subtitle"> {{ user.user.name }} {{ user.user.surname }}</div>
              </div>
              <div class="candidates-list-item_after">
                {{$store.state.static.accessTypes.find(type=>type.type===user.type).name }}
              </div>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <Prompt
      ref="prompt-cancel-vacancy"
      title="Отмена вакансии"
      message="Комментарий или причина отмены"
      placeholder="Введите текст.."
      :validate="() => true"
      @accept="cancelVacancy"
    />
    <Confirm
      ref="confirm-reopen-vacancy"
      title="Открыть вакансию заново ?"
      @accept="reopenVacancy"
    />
    <PopoverList :control-buttons="vacancy.status==='1' ? mainOptionButtons : archivedOptions" :is-slot="true">
      <template v-slot:activator>
        <div class="vacancy-header-buttons" v-if="vacancy.access_type !== 'read'">
          <div class="main-button">Действия</div>
          <div class="extra-button">
            <div class="icon icon-arrow rotate-left"></div>
          </div>
        </div>
      </template>
    </PopoverList>
    <OnCancel ref="on-cancel-modal"/>
    <ShareVacancy ref="share_vacancy_modal"/>
  </div>
</template>

<script>
import ShareVacancy from '@/views/vacancy/ShareModal';
import OnCancel from '@/views/vacancy/close/OnCancel';
export default {
  name: 'RightContent',
  components: { OnCancel, ShareVacancy },

  props: ['vacancy', 'isShared', 'vacancyLoading'],
  data() {
    return {
      mainOptionButtons: [
        {
          text: 'Вакансия отменена',
          icon: 'mdi-close',
          color: 'red_color',
          handler: () => {
            this.$refs['prompt-cancel-vacancy'].open();
          },
        },
        {
          text: 'Кандидат подобран',
          icon: 'mdi-check',
          color: 'green_color',
          handler: () => {
            this.$eventBus.emit('close-vacancy-modal-open', {
              vacancyId: this.vacancy.id,
            });
          },
        },
        {
          text: 'Поделиться',
          icon: 'mdi-share-variant-outline',
          color: '',
          handler: () => {
            this.$refs.share_vacancy_modal.open({ vacancy: this.vacancy });
          }
        }
      ],
      archivedOptions: [
        {
          text: 'Открыть заново',
          icon: 'mdi-reload',
          color: 'green_color',
          handler: () => this.openConfirmReopenVacancy(),
        },
        {
          text: 'Поделиться',
          icon: 'mdi-share-variant-outline',
          color: '',
          handler: () => {
            this.$refs.share_vacancy_modal.open({ vacancy: this.vacancy });

          }
        }
      ],
    };
  },
  computed: {
    firstCandidates() {
      return this.vacancy.candidates ? this.vacancy.candidates.slice(0, 6) : [];
    },
  },
  methods: {
    cancelVacancy(comment) {
      this.$server.request2('vacancy/cancel', {
        id: this.vacancy.id,
        comment,
      }, () => {
        this.$refs['on-cancel-modal'].open();
        setTimeout(()=>this.$router.replace('/vacancies'), 2000);
      });
    },
    reopenVacancy() {
      this.$server.request2('vacancy/open', { id: this.vacancy.id }, () => {
        this.$eventBus.emit('load-vacancy');
      });
    },
    openConfirmReopenVacancy() {
      this.$refs['confirm-reopen-vacancy'].open();
    },
  },
};
</script>

<style lang="scss">
.vacancy-right-content {
  right: 40px;
}
.vacancy-candidates {
  margin-bottom: 40px;

  &_list {
    display: flex;
    align-items: center;
    margin-right: 22px;
    position: relative;
    width: fit-content;

    .candidates-count {
      top: -9px;
      right: -9px;
      position: absolute;
      font-weight: bold;
      font-size: 10px;
      border-radius: 50%;
      padding: 1px;
      display: block;
      width: 16px;
      height: 16px;
    }

    & > * {
      margin-right: -14px;
    }
  }

  .candidate-media {
    width: 48px;
    height: 48px;
    border: 2px solid #FFFFFF;
  }
}

.shared-users {
  .v-expansion-panel-content__wrap {
    padding-left: 0;
  }
  .candidates-list-item_title {
    overflow-x: hidden;
    word-break: normal;
    text-overflow: ellipsis;
  }
  .candidates-list-item_after {
    width: 100px;
    justify-content: flex-end;
  }
}


.vacancy-header-buttons {
  display: flex;

  & > * {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }

  .main-button {
    width: 100%;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
    background: rgba(var(--page-color-main-rgb), .08);
  }

  .extra-button {
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--page-color-main-rgb), .08);
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .icon {
      font-size: 12px;
    }
  }
}

</style>