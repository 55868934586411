<template>
  <div class="page half-width page-folder with-right-fixed-content">
    <div class="page-content">
      <div class="page-header">
        <div class="page-header_left">
          <div class="page-header_back-link" @click="$router.back()">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <div class="page-header-title">
            <div class="page-title" v-if="folderIndex === 'all'">Все: {{ vacancy.candidates.length }}</div>
            <div class="page-title" v-else-if="folderIndex === 'not_suitable'">Не подходит: {{ notSuitableCandidates.length }}</div>
            <div class="page-title" v-else-if="vacancy.funnel">{{ vacancy.funnel[+folderIndex].title }}:
              {{ vacancy.candidates.filter((candidate) => +candidate.folder === +folderIndex && !+candidate.not_suitable).length }}&nbsp;<span
                style="color: rgba(var(--page-color-main-rgb), 0.25)"
                class="ml-1">({{ vacancy.candidates.filter((candidate) => +candidate.folder === +folderIndex).length }})</span>
            </div>
            <div
              v-if="vacancy.access_type !== 'read'"
              class="add-candidate-button transparent-button"
              @click="$eventBus.emit('add-candidates-modal-open',
              {multiple: true, needLoad: true,
              vacancyId: vacancy.id,
        alreadyExistsCandidates: vacancy.candidates.map((candidate)=> candidate.candidate.id), from: 'folder_list', folder: folderIndex})">
              <div class="icon icon-new"></div>
              <span>Новый кандидат</span>
            </div>
          </div>
        </div>
      </div>
      <Searchbar name="search" placeholder="Поиск" @input="filterCandidates"/>
      <v-data-table
        v-model="selectedCandidates"
        :headers="tableHeaders"
        :items="filteredCandidates"
        class="elevation-0"
        no-data-text="Кандидатов нет"
        loading-text="Загрузка кандидатов..."
        item-key="candidate.id"
        :loading="loadingTable"
        hide-default-footer
        :show-select="vacancy.access_type !== 'read'"
        disable-sort
        @click:row="rowClicked"
        :items-per-page="100"
      >
        <template v-slot:item.data-table-select="{isSelected,select}">
          <div style="width: 100%; height: 100%; display: flex; padding-left: 10px" @click.stop>
            <v-simple-checkbox :value="isSelected" @input="select($event)"/>
          </div>
        </template>
        <template v-slot:item.name="{item}">
          <CandidateInfoBlock :item="item" :not-suitable="+item.not_suitable"/>
        </template>
        <template v-slot:item.comment="{item}">
          <ItemComment :item="item.candidate"/>
        </template>
        <template v-slot:item.folder="{item}">
          <CandidateFolder :candidate="item" :funnel="vacancy.funnel"/>
        </template>
        <template v-slot:item.more="{item}">
          <PopoverList :control-buttons="ControlPanelButtons(item).filter(e=>Object.keys(e).length)" :item="item" v-if="vacancy.access_type !== 'read'"/>
        </template>
        <template #no-data>
          <NoResults class="mt-12">
            <template v-slot:title>Кандидатов пока нет</template>
          </NoResults>
        </template>
      </v-data-table>
    </div>
    <RightContent :vacancy="vacancy" :vacancy-loading="loadingTable"/>
    <ControlPanel
      :control-buttons="ControlPanelButtons().filter(e=>Object.keys(e).length && e.type === 'global')"
      :items="selectedCandidates"
    />
    <Confirm ref="confirm-remove-vacancy" cancel-text="Нет" accept-text="Да"
             message="Открепить от вакансии?" @accept="removeFromVacancy"/>
    <CreateEventModal ref="create_event_modal"/>
    <ConflictVacancyModal ref="conflict_vacancy_modal" @on-submit="getVacancy"/>
  </div>
</template>

<script>
import ControlPanel from '@/components/ControlPanel.vue';
import RightContent from '@/views/vacancy/RightContent.vue';
import ConflictVacancyModal from '@/views/vacancy/ConflictVacancyModal.vue';
import CreateEventModal from '@/views/event/create/CreateEventModal';

export default {
  name: 'Folder',
  components: {
    CreateEventModal,
    ControlPanel,
    RightContent,
    ConflictVacancyModal,
  },
  data() {
    return {
      folderIndex: this.$route.params.folder,
      vacancy: { candidates: [] },
      conflictData: {},
      filterParams: {
        query: '',
        vacancy: this.$route.params.vacancyId,
      },
      selectedCandidates: [],
      tableHeaders: [
        {
          text: '',
          align: 'start',
          value: 'name',
        },
        {
          text: '',
          align: 'start',
          value: 'comment',
        },
        {
          text: '',
          align: 'start',
          value: 'folder',
        },
        {
          text: '',
          align: 'end',
          value: 'more',
        },
      ],
      loadingTable: false,
      filteredCandidates: [],
      isShared: this.$route.path.split('/').includes('shr'),
    };

  },
  methods: {
    getVacancy() {
      this.loadingTable = true;
      this.$server.request2(`vacancy/get2/${this.$route.params.vacancyId}`, {}, (data) => {
        this.vacancy = data.response;
        this.filteredCandidates = this.folderIndex === 'all' ? this.vacancy.candidates : this.folderIndex === 'not_suitable' ? this.notSuitableCandidates :
          [...this.foldersCandidates.filter((candidate) => +candidate.folder === +this.folderIndex && !+candidate.not_suitable),
            ...this.foldersCandidates.filter((candidate) => +candidate.not_suitable)];
        this.candidates = this.filteredCandidates;
      }, () => {
      }, () => {
        this.loadingTable = false;
      });
    },
    removeFromVacancy() {
      const vacancyId = this.vacancy.id;
      this.$server.request2(`vacancy/removeCandidate/${vacancyId}`, {
        ids: this.selectedCandidates.map((candidate) => candidate.candidate.id)
      }, () => {
        this.getVacancy();
        this.selectedCandidates = [];
      });
    },
    rowClicked(item) {
      if (this.isShared) this.$router.push(`/shr/${this.$route.params.token}/${item.candidate.id}?vacancy=${this.vacancy.id}`);
      else this.$router.push(`/candidates/candidate/${item.candidate.id}/`);
    },

    filterCandidates(value) {
      if (value.length) {
        this.filteredCandidates = this.candidates.filter((candidate) => {
          let fullname = `${candidate.candidate.name + candidate.candidate.surname}`.toLowerCase();
          if (fullname.includes(value.toLowerCase())) return candidate;
        });
      } else {
        this.filteredCandidates = this.candidates;
      }
    },
    ControlPanelButtons(candidate = {}) {
      return [
        {
          text: 'Переместить',
          type: 'global',
          icon: 'mdi-folder',
          handler: (items) => {
            this.$eventBus.emit('change-folder-candidates-modal-open', {
              candidateIds: items.map((item) => item.candidate.id),
              vacancyId: this.vacancy.id,
              folder: this.folderIndex*1>=0 ? this.folderIndex.toString() : null
            });
          },
        },
        {
          text: 'Поделиться',
          type: 'global',
          icon: 'mdi-share-variant-outline',
          handler: (items) => {
            this.$server.request2('candidate/createAccessToken', { ids: items.map((item) => item.candidate.id) }, (data) => {
              this.$eventBus.emit('open-share-candidate-modal', { token: data.response });
            });
          },
        },
        {
          text: 'Назначить собеседование',
          icon: 'mdi-calendar',
          handler: (items) => {
            let candidate = { ...items[0].candidate };
            candidate.image = items[0].image;
            this.$refs.create_event_modal.open({type: '0', vacancy: this.vacancy, candidate});
          },
        },
        Object.keys(candidate).length && candidate.not_suitable*1 === 0 ?
        {
          text: 'Не подходит',
          color: 'red_color',
          icon: 'mdi-close',
          type: 'global',
          handler: (items) => {
            this.$server.request2('candidate/changeFolder', {
              vacancy: this.vacancy.id,
              candidates: items.map((item) => item.candidate.id),
              not_suitable: 1,
            }, () => {
              this.getVacancy();
              this.selectedCandidates = [];
            });
          },
        } : {},
        {
          text: 'Открепить от вакансии',
          type: 'global',
          icon: 'mdi-pin-off-outline',
          color: 'red_color',
          handler: (items) => {
            this.selectedCandidates = items;
            this.$refs['confirm-remove-vacancy'].open();
          },
        },
      ];
    },
    getSharedVacancy() {
      this.loadingTable = true;
      this.$server.request2('vacancy/getByToken', {at: this.$route.params.token, vacancy: this.$route.params.vacancyId}, (data)=>{
        this.vacancy = data.response;
        this.filteredCandidates = this.folderIndex === 'all' ? this.vacancy.candidates : this.folderIndex === 'not_suitable' ? this.notSuitableCandidates :
          [...this.foldersCandidates.filter((candidate) => +candidate.folder === +this.folderIndex && !+candidate.not_suitable),
            ...this.foldersCandidates.filter((candidate) => +candidate.not_suitable)];
        this.candidates = this.filteredCandidates;
        this.loadingTable = false;
      }, ()=>{
        this.loadingTable = false;
      });
    }
  },
  computed: {
    foldersCandidates() {
      return !['all', 'not_suitable'].includes(this.folderIndex) ? this.vacancy.candidates.filter((candidate) => +candidate.folder === +this.folderIndex) : [];
    },
    notSuitableCandidates() {
      return this.folderIndex === 'not_suitable' ? this.vacancy.candidates.filter((candidate) => +candidate.not_suitable === 1) : [];
    },
  },
  mounted() {
    if (this.isShared) this.getSharedVacancy();
    else this.getVacancy();
  },
  created() {
    this.$eventBus.on('candidates-selected-to-folder', (_data) => {
      this.$server.request2(`vacancy/addCandidates/${this.vacancy.id}`, _data, (data) => {
        if (!data.errors.length) {
          this.getVacancy();
          return;
        }
        const conflicts = data.errors.map(([candidateId, currentVacancy]) => {
          return {
            newVacancy: this.vacancy,
            currentVacancy,
            candidateInfo: currentVacancy.candidates.find((candidate) => candidate.id === candidateId.toString()),
            folder: this.folderIndex,
            selectedCandidate: 0,
          };
        });
        this.$refs.conflict_vacancy_modal.open({ conflicts, vacancyId: this.vacancy.id });
      });
    });
    this.$eventBus.on('update-candidate-in-folder', () => this.getVacancy());
  },
  beforeDestroy() {
    this.$eventBus.off('candidates-selected-to-folder, update-candidate-in-folder');
  },
};
</script>

<style lang="scss">
.page-folder {
  .page-header {
    margin-bottom: 28px;

    &_left {
      flex-grow: 1;
    }

    .page-header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .page-title {
        font-size: 28px;
        line-height: 28px;
      }

      .add-candidate-button {
        width: auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 6px;
          font-size: 11px;
          font-weight: 600;
        }
      }
    }
  }

  .candidate-main-info {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;

    &_media {
      position: relative;
      width: 42px;
      height: 42px;
      margin-right: 18px;
      flex-shrink: 0;

      .candidate-media {
        width: 100%;
        height: 100%;
      }

      .candidate-rating {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &_content {
      & > * {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 16px;
      color: rgba(var(--page-color-main-rgb), .5);
    }

  }

  .control-panel {
    position: fixed;
    bottom: 0;
  }
}


</style>
