<template>
  <div class="control-panel" :class="{'show-in': items.length}">
    <div class="control-panel_content">
      <div class="control-panel_number-items">
        <div class="icon icon-check"></div>
        <span>Выбрано: {{ items.length }}</span>
      </div>
      <div class="control-panel_buttons">
        <v-btn
          class="mr-6"
          v-for="( btn, index ) in controlButtons"
          :key="index"
          depressed
          :color="'#ffffff'"
          @click="btn.handler(items)"
          :disabled="btn.count === 0"
        >
          {{btn.text}} ({{ btn.hasOwnProperty('count') ? btn.count : items.length }})
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ControlPanel',
  props: ['controlButtons', 'items'],
  methods: {},
  computed: {
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.control-panel {
  width: 100%;
  background: var(--color-gray);
  display: block;
  transition: height 200ms ease-in-out;
  height: 0;
  &.show-in {
    height: fit-content;
  }

  &_content {
    width: 100%;
    height: 100%;
    min-height: 72px;
    padding-right: var(--page-offset-right);
    padding-left: var(--page-offset-left);
    display: flex;
    align-items: center;
  }

  &_number-items {
    margin-right: 15px;
    display: flex;
    flex-shrink: 0;

    .icon {
      font-size: 18px;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &_buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
  }
}
</style>
