<template>
  <Modal ref="modal" title="Вакансия отменена">
    <div class="completion-modal cancel">
      <img
        src="@/images/pictures/picture-1.svg"
        alt="vacancy closed picture"
        class="completion-modal_icon-1"
      >
      <div class="completion-modal_check">
        <div class="icon icon-check"></div>
      </div>
      <NoResults>
        <template #text>
          Она появится в архиве вакансий. Если нужно, вы можете открыть ее заново.
        </template>
      </NoResults>
    </div>
    <template #actions>
      <div class="default-button" @click="$refs.modal.close()">Готово</div>
    </template>
  </Modal>
</template>

<script>

import Modal from '@/components/Modal';
export default {
  name: 'OnCancel',
  components: { Modal },
  methods: {
    open() {
      console.log('opened');
      this.$refs.modal.open();
    }
  }
};
</script>

<style scoped>

</style>